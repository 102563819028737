/**
 * @file Use
 */

import { computed, watch } from 'vue';

import dayjs from 'dayjs';

import regionMap from '@/common/regionMap.json';

import { isEmptyObject, isNullOrUndefined } from '@/common/tools';

dayjs.extend(require('dayjs/plugin/weekOfYear'));

export function useModel(route) {
    return computed(() => route.params.model);
}

export function useNodes(route) {
    return computed(() => route.params.name.split('>'));
}

export function useRouteWatcher(route) {
    return callback => {
        return watch(route, val => {
            if (val.name !== 'cost_view') {
                return;
            }
            callback && callback();
        });
    };
}

export function useGranularity(route) {
    return computed(() => route.query.granularity || 'day');
}

export function useTag(route) {
    return computed(() => {
        if (!route.query.tk) {
            return null;
        }
        let tag = [route.query.tk];
        if (route.query.tv) {
            tag.push(route.query.tv);
        }
        return tag;
    });
}

export function useFilters(route) {
    return computed(() => {
        let include = {};
        let exclude = {};
        let correction = null;
        let multiViewKeys = ['service', 'detail', 'region', 'cloud'];
        let excludeKeys = route.query.exkeys
            ? route.query.exkeys.split(',')
            : [];
        multiViewKeys.forEach(key => {
            if (route.query[key] && route.query[key].length > 0) {
                let v = route.query[key];
                // 这里需要简易深拷贝，所以即使v === object，也需要写成[...v]
                (excludeKeys.includes(key) ? exclude : include)[key] =
                    typeof v === 'object' ? [...v] : [v];
            }
        });
        // 还原RI
        if (+route.query.purchase === 1) {
            exclude.purchase = [];
            correction = [];
        }
        // 排除RI
        if (+route.query.purchase === 2) {
            exclude.purchase = ['ri'];
            correction = [];
        }

        // 特殊exclude.detail "savingplay_unused"的处理
        exclude.detail = isEmptyObject(exclude.detail) ? [] : exclude.detail;
        // SavingPlan使用
        if (+route.query.savingpu !== 1) {
            exclude.detail.push('savingplan_unused');
        }

        return {
            ...(Object.keys(include).length > 0 ? { include } : {}),
            ...(Object.keys(exclude).length > 0 ? { exclude } : {}),
            ...(correction ? { correction } : {}),
        };
    });
}

export function formatAxisData(axisData, granularity = 'day') {
    if (granularity === 'day') {
        return axisData;
    }
    return axisData.map(item => {
        let date = dayjs(item);
        // 周
        if (granularity === 'week') {
            return `第${date.week()}周`;
        }
        // 相对周
        if (granularity === '7') {
            return `第${date.week()}周(${date.format('M/D')}~${date
                .add(6, 'day')
                .format('M/D')})`;
        }
        // 月
        return date.format('YYYY-MM');
    });
}

export function aliasRegionLabel(code) {
    return regionMap[code] ? `${regionMap[code]}[${code}]` : code;
}

export function getSortedLegends(data) {
    let legends = Object.keys(data);
    legends.sort((a, b) => {
        let aVal = Object.values(data[a]).pop();
        let bVal = Object.values(data[b]).pop();
        if (isNullOrUndefined(aVal)) {
            return 1;
        }
        if (isNullOrUndefined(bVal)) {
            return -1;
        }
        return bVal - aVal;
    });
    return legends;
}
