<template>
    <div class="multi-view-analysis-view">
        <MultiViewCoverChart
            class="full-chart-panel"
            name="业务成本分布"
            dimension="node"
            @show-analysis="onShowAnalysisClick"
        ></MultiViewCoverChart>
        <MultiViewCoverChart
            class="full-chart-panel"
            name="标签成本分布"
            dimension="tag"
            @show-analysis="onShowAnalysisClick"
        ></MultiViewCoverChart>
        <div class="double-chart-panel">
            <MultiViewCoverChart
                class="item"
                name="服务成本分布"
                dimension="service"
                @show-analysis="onShowAnalysisClick"
            ></MultiViewCoverChart>
            <MultiViewCoverChart
                class="item"
                name="细分计费分布"
                dimension="detail"
                @show-analysis="onShowAnalysisClick"
            ></MultiViewCoverChart>
        </div>
        <div class="double-chart-panel">
            <MultiViewCoverChart
                class="item"
                name="地域成本分布"
                dimension="region"
                @show-analysis="onShowAnalysisClick"
            ></MultiViewCoverChart>
            <MultiViewCoverChart
                class="item"
                name="云商成本分布"
                dimension="cloud"
                @show-analysis="onShowAnalysisClick"
            ></MultiViewCoverChart>
        </div>
        <n-drawer
            v-model:show="showAnalysisDrawer"
            :width="drawWidth"
            :auto-focus="false"
        >
            <n-drawer-content
                class="analysis-drawer"
                :body-style="{ backgroundColor: '#f9f9f9' }"
            >
                <template #header>
                    <div
                        class="ctrl-bar"
                        :style="{ width: drawWidth - 52 + 'px' }"
                    >
                        <div class="title">{{ dimensionName }}分析</div>
                        <div class="date-range">
                            [{{ dateRange.start_time }} ~
                            {{ dateRange.end_time }}]
                        </div>
                        <div class="granularity-ctrl">
                            <template v-if="drillCount > 0">
                                <n-button
                                    size="small"
                                    style="margin-right: 10px"
                                    @click="onDrillBackBtnClick"
                                >
                                    回退到【{{ drillBackNodeName }}】
                                </n-button>
                            </template>
                            <n-popconfirm
                                v-if="selectDimension === 'node'"
                                @positive-click="onDrillDownOver"
                            >
                                <template #trigger>
                                    <n-button
                                        size="small"
                                        style="margin: 5px 10px 0 0"
                                        >一钻到底</n-button
                                    >
                                </template>
                                一钻到底需要等待很长时间，建议不要在层级较高的节点使用，确定使用么？
                            </n-popconfirm>
                            <span class="label">聚合粒度：</span>
                            <n-radio-group
                                v-model:value="granularity"
                                name="granularity"
                                size="small"
                                class="input"
                                @update:value="onGranularityChange"
                            >
                                <n-radio-button label="日" value="day">
                                </n-radio-button>
                                <n-radio-button label="周" value="week">
                                </n-radio-button>
                                <n-radio-button label="相对周" value="7">
                                </n-radio-button>
                                <n-radio-button label="月" value="month">
                                </n-radio-button>
                            </n-radio-group>
                        </div>
                    </div>
                </template>
                <div class="content">
                    <div class="box">
                        <ChartBox
                            class="pie"
                            :name="dimensionName + '占比'"
                            :loading="costRatioLoading"
                            @mounted="renderCostRatioChart"
                        >
                            <template #header>
                                <n-button
                                    style="margin-top: 5px"
                                    size="tiny"
                                    @click="showCostRatioTable = true"
                                    >占比详情</n-button
                                >
                            </template>
                        </ChartBox>
                        <ChartBox
                            class="full"
                            :name="dimensionName + '同环比'"
                            :loading="costCompareDiffLoading"
                            @mounted="renderCostCompareDiffChart"
                        >
                            <template #header>
                                <n-button-group
                                    style="margin-top: 5px; margin-right: 10px"
                                    size="tiny"
                                >
                                    <n-button
                                        :ghost="costCompareIsYOY"
                                        :type="
                                            costCompareIsYOY
                                                ? 'primary'
                                                : 'default'
                                        "
                                        @click="setCostCompareYOY(true)"
                                        >同比</n-button
                                    >
                                    <n-button
                                        :ghost="!costCompareIsYOY"
                                        :type="
                                            !costCompareIsYOY
                                                ? 'primary'
                                                : 'default'
                                        "
                                        @click="setCostCompareYOY(false)"
                                        >环比</n-button
                                    >
                                </n-button-group>
                                <n-button-group
                                    style="margin-top: 5px"
                                    size="tiny"
                                >
                                    <n-button
                                        :ghost="costCompareValueType === 'diff'"
                                        :type="
                                            costCompareValueType === 'diff'
                                                ? 'primary'
                                                : 'default'
                                        "
                                        @click="setCostCompareValueType('diff')"
                                        >差值</n-button
                                    >
                                    <n-button
                                        :ghost="costCompareValueType === 'rate'"
                                        :type="
                                            costCompareValueType === 'rate'
                                                ? 'primary'
                                                : 'default'
                                        "
                                        @click="setCostCompareValueType('rate')"
                                        >比值</n-button
                                    >
                                </n-button-group>
                            </template>
                        </ChartBox>
                    </div>
                    <div class="box">
                        <ChartBox
                            class="full"
                            :name="dimensionName + '详情'"
                            :loading="costDetailLoading"
                            @mounted="renderCostDetailChart"
                        >
                            <template #header>
                                <n-button
                                    size="tiny"
                                    style="margin-top: 5px; margin-right: 10px"
                                    @click="exportCsvFile"
                                >
                                    CSV
                                </n-button>

                                <n-button-group
                                    style="margin-top: 5px"
                                    size="tiny"
                                >
                                    <n-button
                                        :ghost="costDetailChartType === 'line'"
                                        :type="
                                            costDetailChartType === 'line'
                                                ? 'primary'
                                                : 'default'
                                        "
                                        @click="
                                            switchCostDetailChartType('line')
                                        "
                                        >成本趋势</n-button
                                    >
                                    <n-button
                                        :ghost="costDetailChartType === 'bar'"
                                        :type="
                                            costDetailChartType === 'bar'
                                                ? 'primary'
                                                : 'default'
                                        "
                                        :focusable="false"
                                        @click="
                                            switchCostDetailChartType('bar')
                                        "
                                        >堆叠占比</n-button
                                    >
                                </n-button-group>
                            </template>
                        </ChartBox>
                    </div>
                    <div class="box">
                        <ChartBox
                            class="full"
                            :name="dimensionName + '差额'"
                            :loading="costDiffLoading"
                            @mounted="renderCostDiffChart"
                        >
                            <template #header>
                                <n-button-group
                                    style="margin-top: 5px; margin-right: 10px"
                                    size="tiny"
                                >
                                    <n-button
                                        :ghost="costDiffIsYOY"
                                        :type="
                                            costDiffIsYOY
                                                ? 'primary'
                                                : 'default'
                                        "
                                        @click="setCostDiffYOY(true)"
                                        >同比</n-button
                                    >
                                    <n-button
                                        :ghost="!costDiffIsYOY"
                                        :type="
                                            !costDiffIsYOY
                                                ? 'primary'
                                                : 'default'
                                        "
                                        @click="setCostDiffYOY(false)"
                                        >环比</n-button
                                    >
                                </n-button-group>
                                <n-button-group
                                    style="margin-top: 5px"
                                    size="tiny"
                                >
                                    <n-button
                                        :ghost="costDiffValueType === 'diff'"
                                        :type="
                                            costDiffValueType === 'diff'
                                                ? 'primary'
                                                : 'default'
                                        "
                                        @click="setCostDiffValueType('diff')"
                                        >差值</n-button
                                    >
                                    <n-button
                                        :ghost="costDiffValueType === 'rate'"
                                        :type="
                                            costDiffValueType === 'rate'
                                                ? 'primary'
                                                : 'default'
                                        "
                                        @click="setCostDiffValueType('rate')"
                                        >比值</n-button
                                    >
                                </n-button-group>
                            </template>
                        </ChartBox>
                    </div>
                </div>
            </n-drawer-content>
        </n-drawer>
        <n-modal v-model:show="showCostRatioTable">
            <n-card style="width: 600px" title="成本占比明细">
                <n-data-table
                    size="small"
                    :columns="costRatioTableColumns"
                    :data="costRatioTableData"
                    :max-height="400"
                ></n-data-table>
            </n-card>
        </n-modal>
    </div>
</template>

<style lang="less">
@import '../../../../common/common.less';

.multi-view-analysis-view {
    position: relative;

    .full-chart-panel {
        margin-bottom: 10px;
    }

    .double-chart-panel {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        margin-bottom: 10px;

        .item {
            flex-grow: 1;
            width: 45%;
            overflow: hidden;

            &:nth-child(odd) {
                margin-right: 6px;
            }
            &:nth-child(even) {
                margin-left: 6px;
            }
        }
    }
}

.analysis-drawer {
    .ctrl-bar {
        display: flex;
        flex-direction: row;
        align-items: center;

        .title {
            margin-right: 10px;
        }

        .granularity-ctrl {
            margin-left: auto;
            .label {
                font-size: 14px;
                font-weight: normal;
            }
        }
    }

    .content {
        position: relative;
    }

    .box {
        position: relative;
        display: flex;
        flex-direction: row;
        height: 330px;
        margin-bottom: 12px;

        .full {
            flex-grow: 1;
            .standard-border;
            .standard-shadow;
        }

        .pie {
            width: 330px;
            flex-shrink: 0;
            margin-right: 12px;
            .standard-border;
            .standard-shadow;
        }
    }
}
</style>

<script setup>
import { ref, computed, onMounted, h } from 'vue';
import { useRoute } from 'vue-router';
import {
    NDrawer,
    NDrawerContent,
    NButton,
    NButtonGroup,
    NModal,
    NCard,
    NDataTable,
    NRadioGroup,
    NRadioButton,
    NPopconfirm,
} from 'naive-ui';
import * as echarts from 'echarts/core';
import {
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    GridComponent,
} from 'echarts/components';
import { PieChart, LineChart, BarChart } from 'echarts/charts';
import { LabelLayout, UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';

import { MVBizViewAPI } from '@/common/API';

import MultiViewCoverChart from './MultiViewCoverChart';
import ChartBox from '@/components/ChartBox';
import { useDateRange } from '@/common/QueryDateRange';
import { formatNumber } from '@/common/tools';
import {
    useModel,
    useTag,
    useFilters,
    formatAxisData,
    aliasRegionLabel,
    getSortedLegends,
} from './tools';
import {
    AdvancedTooltip,
    yAxisLabelFormatter,
    exportMatrixData,
} from '@/common/EChartsTools';
import DefaultTooltipBox from '@/components/TooltipBox/DefaultTooltip';
import exportCSV from '@/common/CSVGenerator';
import Log from '@/common/log';

echarts.use([
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    PieChart,
    CanvasRenderer,
    LabelLayout,
    GridComponent,
    LineChart,
    BarChart,
    UniversalTransition,
]);

const commonLegendOpts = {
    show: true,
    orient: 'vertical',
    right: 0,
    bottom: 10,
    top: 'middle',
    type: 'scroll',
    textStyle: {
        width: 80,
        overflow: 'breakAll',
    },
    selector: [
        {
            // 全选
            type: 'all',
            // 可以是任意你喜欢的标题
            title: '全选',
        },
        {
            // 反选
            type: 'inverse',
            // 可以是任意你喜欢的标题
            title: '反选',
        },
    ],
};

const route = useRoute();
const treeType = useModel(route);
const nodes = ref([]);

const tag = useTag(route);
const filter = useFilters(route);

const dateRange = useDateRange(route);

let showAnalysisDrawer = ref(false);

let clientWidth = ref(document.body.clientWidth);
window.addEventListener('resize', () => {
    clientWidth.value = document.body.clientWidth;
});

let drawWidth = computed(() => {
    return clientWidth.value - 200;
});

let selectDimension = ref('');

let granularity = ref('day');

let dimensionName = computed(() => {
    return (
        {
            node: '业务成本',
            leaf: '业务成本',
            tag: '标签成本',
            cloud: '云商成本',
            region: '地域成本',
            service: '服务成本',
            detail: '细分计费',
        }[selectDimension.value] || '成本'
    );
});

function resetAnalysisOpts() {
    costCompareValueType.value = 'diff';
    costCompareIsYOY.value = true;
    costDiffValueType.value = 'diff';
    costDiffIsYOY.value = true;
}

function onShowAnalysisClick(dimension) {
    selectDimension.value = dimension;
    showAnalysisDrawer.value = true;
    granularity.value = route.query.granularity || 'day';
    costDetailChartType.value = 'line';
    drillCount.value = 0;
    nodes.value = route.params.name.split('>');
    loadData();
    Log.click('cost_view_show_analysis_' + dimension);
}

function onGranularityChange(value) {
    Log.click('cost_view_analysis_ctrl_granularity_' + value);
    loadData();
}

let selectDrillNode = ref('');

let drillCount = ref(0);

function onDrillDown(nodeName) {
    Log.click('cost_view_analysis_drill_down');
    nodes.value.push(nodeName);
    loadData();
    drillCount.value++;
}

const drillBackNodeName = computed(() => nodes.value[nodes.value.length - 2]);

function onDrillBackBtnClick() {
    Log.click('cost_view_analysis_drill_back');
    nodes.value.pop();
    loadData();
    drillCount.value--;
}

function onDrillDownOver() {
    drillCount.value = 0;
    Log.click('cost_view_analysis_drill_down_over');
    selectDimension.value = 'leaf';
    loadData();
}

function commonVNodeCreator() {
    return selectDimension.value === 'node'
        ? (params, option) =>
              h(
                  DefaultTooltipBox,
                  {
                      data: params,
                      option,
                  },
                  {
                      itemSuffix: slotProps => (
                          <NButton
                              size="tiny"
                              ghost
                              color="#0B6EE2"
                              style="margin-left:8px"
                              onClick={() =>
                                  onDrillDown(slotProps.series.seriesName)
                              }
                          >
                              下钻
                          </NButton>
                      ),
                  }
              )
        : null;
}

// 成本占比：CostRatio

let costRatioLoading = ref(false);
let costRatioChartInstance = null;
let showCostRatioTable = ref(false);
let costRatioTableColumns = ref([
    {
        title: '名称',
        key: 'name',
    },
    {
        title: '成本',
        key: 'cost',
        render(row) {
            return '$' + row.value.toFixed(2);
        },
    },
    {
        title: '占比',
        key: 'percent',
        render(row) {
            return (row.percent * 100).toFixed(2) + '%';
        },
    },
]);
let costRatioTableData = ref([]);

function renderCostRatioChart(dom) {
    costRatioChartInstance = echarts.init(dom);
    if (selectDimension.value === 'node') {
        costRatioChartInstance.on('click', params => {
            if (params.name !== '[其他]') {
                selectDrillNode.value = params.name;
            }
        });
    }
}

function costRatioDataReceiver(resData) {
    let total = 0;
    let list = Object.keys(resData).map(key => {
        let value = Object.values(resData[key]).pop();
        total += value;
        return {
            name: key,
            value,
        };
    });

    list = list.map(item => ({ ...item, percent: item.value / total }));
    list.sort((a, b) => -1 * (a.percent - b.percent));
    costRatioTableData.value = list;

    let newList = [];
    let ignoreList = [];
    let ignoreValue = 0;
    let totalPercent = 0;
    list.forEach(item => {
        if (totalPercent > 0.95) {
            ignoreList.push(item);
            ignoreValue += item.value;
            return;
        }
        newList.push(item);
        totalPercent += item.percent;
    });
    if (ignoreList.length > 2) {
        newList.push({
            name: '[其他]',
            value: ignoreValue,
            percent: 1 - totalPercent,
        });
    } else {
        newList.push(...ignoreList);
    }

    costRatioChartInstance.setOption({
        tooltip: {
            trigger: 'item',
            formatter: params => {
                return `
                        ${params.seriesName}
                        <div style="display:flex;flex-direction:row;align-items:center;">
                            ${params.marker}
                            <div>${params.name}&nbsp;&nbsp;<span style="font-weight:bold;">${params.value} (${params.percent}%)</span></div>
                        </div>
                    `;
            },
            appendToBody: true,
        },
        legend: {
            type: 'scroll',
            left: 'center',
            bottom: 0,
        },
        series: [
            {
                name: '成本占比',
                type: 'pie',
                center: ['50%', '45%'],
                radius: '60%',
                data: newList.map(item => ({
                    name:
                        selectDimension.value === 'region'
                            ? aliasRegionLabel(item.name)
                            : item.name,
                    value: +item.value.toFixed(2),
                })),
            },
        ],
    });
}

// 成本同环比：CostCompareDiff

let costCompareDiffLoading = ref(false);
let costCompareDiffChartInstance = null;
let costCompareDiffChartTooltip = new AdvancedTooltip();
let costCompareIsYOY = ref(true);
let costCompareValueType = ref('diff');

function renderCostCompareDiffChart(dom) {
    costCompareDiffChartInstance = echarts.init(dom);
}

function costCompareDiffDataReceiver(resData) {
    let unit = costCompareValueType.value;
    let legends = getSortedLegends(resData);
    let vk = Object.keys(resData[legends[0]]).pop();
    let seriesData = legends.map(name => ({
        name,
        value: resData[name][vk],
    }));
    seriesData.sort((a, b) => -1 * (a.value - b.value));
    costCompareDiffChartInstance.setOption({
        grid: {
            top: 10,
            left: 56,
            right: 180,
            bottom: 8,
        },
        legend: {
            data: legends.map(name =>
                selectDimension.value === 'region'
                    ? aliasRegionLabel(name)
                    : name
            ),
            ...commonLegendOpts,
        },
        tooltip: costCompareDiffChartTooltip.tooltip({
            maxHeight: 280,
            vNodeCreator: commonVNodeCreator(),
            valueFormatter: value =>
                unit === 'diff'
                    ? formatNumber(value)
                    : `${(value * 100).toFixed(2)}%`,
            opt: {
                position: (point, params, dom, rect, size) => {
                    let [x, y] = point;
                    let [cWidth, cHeight] = size.contentSize;
                    let positionX = x < 400 ? x + 10 : x - cWidth - 10;
                    let positionY = y < 70 ? y + 10 : y - cHeight - 5;
                    return [positionX, positionY > -100 ? positionY : -100];
                },
            },
        }),
        xAxis: {
            type: 'category',
            data: [costCompareIsYOY.value ? '同比' : '环比'],
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                formatter: value =>
                    unit === 'diff'
                        ? formatNumber(value, 0)
                        : `${(value * 100).toFixed(2)}%`,
            },
        },
        series: seriesData.map(item => ({
            type: 'bar',
            name:
                selectDimension.value === 'region'
                    ? aliasRegionLabel(item.name)
                    : item.name,
            data: [item.value && +item.value.toFixed(unit === 'diff' ? 2 : 4)],
        })),
    });
}

function loadCostCompareDiff() {
    loadAnalysisData(
        [costCompareDiffDataReceiver],
        [costCompareDiffLoading],
        costCompareValueType.value,
        costCompareIsYOY.value
    );
}

function setCostCompareYOY(isYOY) {
    if (isYOY === costCompareIsYOY.value) {
        return;
    }
    costCompareIsYOY.value = isYOY;
    loadCostCompareDiff();
    Log.click(
        `cost_view_analysis_show_compare_diff_is_${isYOY ? 'yoy' : 'chain'}`
    );
}

function setCostCompareValueType(type) {
    if (type === costCompareValueType.value) {
        return;
    }
    costCompareValueType.value = type;
    loadCostCompareDiff();
    Log.click('cost_view_analysis_show_compare_diff_' + type);
}

// 成本详情：costDetail
let costDetailLoading = ref(false);
let costDetailChartInstance = null;
let costDetailChartTooltip = new AdvancedTooltip();
// 成本趋势：line；堆叠占比：bar；
let costDetailChartType = ref('line');
let costDetailData = null;

function renderCostDetailChart(dom) {
    costDetailChartInstance = echarts.init(dom);
}

function drawCostDetailChartOption() {
    let legends = getSortedLegends(costDetailData);
    let axisData = Object.keys(costDetailData[legends[0]]);
    costDetailChartInstance.setOption({
        grid: {
            top: 10,
            left: 56,
            right: 140,
            bottom: 30,
        },
        tooltip: costDetailChartTooltip.tooltip({
            maxHeight: 200,
            vNodeCreator: commonVNodeCreator(),
            valueFormatter: value => formatNumber(value),
            seriesItemRatio: { show: costDetailChartType.value === 'bar' },
            seriesTotal: { show: costDetailChartType.value === 'bar' },
        }),
        legend: {
            data: legends.map(name =>
                selectDimension.value === 'region'
                    ? aliasRegionLabel(name)
                    : name
            ),
            ...commonLegendOpts,
        },
        xAxis: {
            type: 'category',
            data: formatAxisData(axisData, granularity.value),
        },
        yAxis: {
            type: 'value',
            ...yAxisLabelFormatter(),
        },
        series: legends.map(key => ({
            name:
                selectDimension.value === 'region'
                    ? aliasRegionLabel(key)
                    : key,
            type: costDetailChartType.value,
            ...(costDetailChartType.value === 'bar' ? { stack: 'total' } : {}),
            showSymbol: false,
            data: axisData.map(
                date =>
                    costDetailData[key][date] &&
                    +costDetailData[key][date].toFixed(2)
            ),
            triggerLineEvent: true,
        })),
    });
}

function costDetailDataReceiver(resData) {
    costDetailData = resData;
    drawCostDetailChartOption();
}

function switchCostDetailChartType(type) {
    if (type === costDetailChartType.value) {
        return;
    }
    costDetailChartType.value = type;
    drawCostDetailChartOption();
    Log.click('cost_view_analysis_detail_' + costDetailChartType.value);
}

function exportCsvFile() {
    let data = exportMatrixData(costDetailChartInstance);
    let columns = [
        { key: 'x', label: '时间' },
        ...Object.keys(data.columns).map(key => ({
            key,
            label: data.columns[key],
        })),
    ];
    exportCSV(data.list, columns);
}

// 成本差额：CostDiff

let costDiffLoading = ref(false);
let costDiffChartInstance = null;
let costDiffChartTooltip = new AdvancedTooltip();
let costDiffIsYOY = ref(true);
let costDiffValueType = ref('diff');

function renderCostDiffChart(dom) {
    costDiffChartInstance = echarts.init(dom);
}

function costDiffDataReceiver(resData) {
    let unit = costDiffValueType.value;
    let legends = getSortedLegends(resData);
    let axisData = Object.keys(resData[legends[0]]);
    costDiffChartInstance.setOption({
        grid: {
            top: 10,
            left: 56,
            right: 140,
            bottom: 30,
        },
        tooltip: costDiffChartTooltip.tooltip({
            maxHeight: 200,
            vNodeCreator: commonVNodeCreator(),
            valueFormatter: value =>
                unit === 'diff'
                    ? formatNumber(value)
                    : `${(value * 100).toFixed(2)}%`,
        }),
        legend: {
            data: legends.map(name =>
                selectDimension.value === 'region'
                    ? aliasRegionLabel(name)
                    : name
            ),
            ...commonLegendOpts,
        },
        xAxis: {
            type: 'category',
            data: formatAxisData(axisData, granularity.value),
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                formatter: value =>
                    unit === 'diff'
                        ? formatNumber(value, 0)
                        : `${(value * 100).toFixed(2)}%`,
            },
        },
        series: legends.map(key => ({
            name:
                selectDimension.value === 'region'
                    ? aliasRegionLabel(key)
                    : key,
            type: 'line',
            showSymbol: false,
            data: axisData.map(
                date =>
                    resData[key][date] &&
                    +resData[key][date].toFixed(unit === 'diff' ? 2 : 4)
            ),
            areaStyle: {},
            triggerLineEvent: true,
        })),
    });
}

function loadCostDiff() {
    loadAnalysisData(
        [costDiffDataReceiver],
        [costDiffLoading],
        costDiffValueType.value,
        costDiffIsYOY.value
    );
}

function setCostDiffYOY(isYOY) {
    if (isYOY === costDiffIsYOY.value) {
        return;
    }
    costDiffIsYOY.value = isYOY;
    loadCostDiff();
    Log.click(`cost_view_analysis_show_diff_is_${isYOY ? 'yoy' : 'chain'}`);
}

function setCostDiffValueType(type) {
    if (type === costDiffValueType.value) {
        return;
    }
    costDiffValueType.value = type;
    loadCostDiff();
    Log.click('cost_view_analysis_show_diff_' + type);
}

function clearAllCharts() {
    costRatioChartInstance && costRatioChartInstance.clear();
    costCompareDiffChartInstance && costCompareDiffChartInstance.clear();
    costDetailChartInstance && costDetailChartInstance.clear();
    costDiffChartInstance && costDiffChartInstance.clear();
}

function loadViewData(receivers = [], loadingRefs = []) {
    loadingRefs.length > 0 &&
        loadingRefs.forEach(loading => (loading.value = true));
    return MVBizViewAPI.getViewData({
        ...dateRange.value,
        tree_type: treeType.value,
        node: nodes.value,
        dimension: selectDimension.value,
        granularity: granularity.value,
        ...(tag.value ? { tag: tag.value } : {}),
        ...filter.value,
    })
        .then(res => {
            loadingRefs.length > 0 &&
                loadingRefs.forEach(loading => (loading.value = false));
            if (res.error_no !== 0) {
                return;
            }
            receivers.length > 0 && receivers.forEach(func => func(res.data));
            return Promise.resolve({ success: true });
        })
        .catch(err => {
            loadingRefs.length > 0 &&
                loadingRefs.forEach(loading => (loading.value = false));
            console.log(err);
            return Promise.reject(err);
        });
}

function loadAnalysisData(
    receivers = [],
    loadingRefs = [],
    unit = 'diff',
    isYoY = true
) {
    loadingRefs.length > 0 &&
        loadingRefs.forEach(loading => (loading.value = true));
    let cycle = isYoY
        ? { day: 7, week: 4, 7: 4, month: 12 }[granularity.value] || 7
        : 1;
    MVBizViewAPI.getAnalysisData({
        ...dateRange.value,
        tree_type: treeType.value,
        node: nodes.value,
        dimension: selectDimension.value,
        granularity: granularity.value,
        cycle,
        unit,
        ...(tag.value ? { tag: tag.value } : {}),
        ...filter.value,
    })
        .then(res => {
            loadingRefs.length > 0 &&
                loadingRefs.forEach(loading => (loading.value = false));
            if (res.error_no !== 0) {
                return;
            }
            receivers.length > 0 && receivers.forEach(func => func(res.data));
            return Promise.resolve({ success: true });
        })
        .catch(err => {
            loadingRefs.length > 0 &&
                loadingRefs.forEach(loading => (loading.value = false));
            console.log(err);
        });
}

function loadData() {
    clearAllCharts();
    resetAnalysisOpts();
    // 每次拉取新数据时，清空之前的Node选择
    selectDrillNode.value = '';
    loadViewData(
        [costRatioDataReceiver, costDetailDataReceiver],
        [costRatioLoading, costDetailLoading]
    );
    loadAnalysisData(
        [costCompareDiffDataReceiver, costDiffDataReceiver],
        [costCompareDiffLoading, costDiffLoading]
    );
}

onMounted(() => {
    if (route.query.analysisView) {
        setTimeout(() => {
            onShowAnalysisClick(route.query.analysisView);
        }, 1000);
    }
});
</script>
