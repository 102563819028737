<template>
    <n-modal :show="props.show" size="small" @mask-click="onMaskClick">
        <n-card style="width: 400px" title="创建成本监控" size="small">
            <n-form
                ref="formRef"
                :model="formData"
                :rules="formRules"
                label-placement="left"
                :label-width="84"
            >
                <n-form-item label="名称" path="name">
                    <n-input
                        v-model:value="formData.name"
                        size="small"
                    ></n-input>
                </n-form-item>
                <n-form-item label="触发条件">
                    <n-input-group>
                        <n-select
                            v-model:value="formData.trigger.type"
                            size="small"
                            style="width: 200px"
                            :options="triggerTypeOpts"
                        ></n-select>
                        <n-select
                            v-model:value="formData.trigger.operator"
                            size="small"
                            style="width: 200px"
                            :options="triggerOperatorOpts"
                        ></n-select>
                        <n-input-number
                            v-model:value="formData.trigger.value"
                            size="small"
                        ></n-input-number>
                    </n-input-group>
                </n-form-item>
                <n-form-item label="Token" path="token">
                    <n-input
                        v-model:value="formData.token"
                        size="small"
                    ></n-input>
                </n-form-item>
                <n-form-item label="关注人">
                    <n-dynamic-tags
                        v-model:value="formData.follow"
                        size="small"
                    ></n-dynamic-tags>
                </n-form-item>
                <n-form-item v-if="showSource" label="数据源">
                    <n-input
                        :value="JSON.stringify(props.source, null, '  ')"
                        type="textarea"
                        :autosize="{ minRows: 6, maxRows: 10 }"
                        readonly
                    />
                </n-form-item>
            </n-form>
            <template #footer>
                <div class="footer">
                    <div class="source-enable">
                        <span style="margin-right: 4px">显示数据源</span
                        ><n-switch
                            v-model:value="showSource"
                            size="small"
                        ></n-switch>
                    </div>
                    <n-button
                        size="small"
                        type="primary"
                        :loading="loading"
                        @click="submit"
                        >提交</n-button
                    >
                </div>
            </template>
        </n-card>
    </n-modal>
</template>

<style lang="less" scoped>
.footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .source-enable {
        font-size: 12px;
        display: flex;
        align-items: center;
    }
}
</style>

<script setup>
import { ref } from 'vue';
import {
    NForm,
    NFormItem,
    NInput,
    NInputNumber,
    NSelect,
    NInputGroup,
    NButton,
    NDynamicTags,
    NModal,
    NCard,
    NSwitch,
    useMessage,
} from 'naive-ui';

import { AlertAPI } from '@/common/API';

const props = defineProps({
    show: {
        type: Boolean,
        default: false,
    },
    sourceType: {
        type: String,
        validator(value) {
            return ['cost_tree', 'cost_madx', 'cost_lens'].indexOf(value) > -1;
        },
    },
    source: {
        type: Object,
    },
});

const events = defineEmits(['update:show', 'finished']);

const message = useMessage();

function onMaskClick() {
    events('update:show', false);
}

let triggerTypeOpts = ref([
    {
        label: '成本值',
        value: 0,
    },
    {
        label: '同比',
        value: 1,
    },
    {
        label: '环比',
        value: 2,
    },
]);

let triggerOperatorOpts = ref([
    {
        label: '>=',
        value: 0,
    },
    {
        label: '<=',
        value: 1,
    },
]);

let showSource = ref(false);

let formRef = ref();

let formRules = ref({
    name: {
        required: true,
        message: '名称不能为空',
        trigger: 'blur',
    },
    token: {
        required: true,
        message: 'Token不能为空',
        trigger: 'blur',
    },
});

function genFormData() {
    return {
        name: '',
        trigger: {
            type: 0,
            operator: 0,
            value: 0,
        },
        token: '',
        follow: [],
    };
}

let formData = ref(genFormData());

let loading = ref(false);

function submit() {
    formRef.value.validate(errors => {
        if (errors) {
            return;
        }

        loading.value = true;
        let payload = {
            ...formData.value,
            sourceType: props.sourceType,
            source: props.source,
        };

        AlertAPI.createAlert(payload).then(res => {
            if (res.error_no !== 0) {
                message.error(res.error_msg || '未知异常导致加载失败');
                return;
            }
            message.success(
                '创建完成，您可以去成本监控告警页面管理已创建的监控'
            );
            loading.value = false;
            onMaskClick();
            formData.value = genFormData();
        });
    });
}
</script>
