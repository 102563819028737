/**
 * @file JSXComponents
 * @description 轻量的JSX组件写在这里
 */

import styles from './JSXComponents.module.less';

import { defineComponent } from 'vue';

import { isNullOrUndefined } from '@/common/tools';

export const StandardNumberLabel = defineComponent({
    props: {
        value: {
            type: Number,
        },
        color: {
            type: String,
            validator: value => ['normal', 'red', 'green'].includes(value),
            default: 'normal',
        },
        prefix: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            default: 'format',
            validator: value => ['origin', 'format', 'percent'].includes(value),
        },
        fixedCount: {
            type: Number,
            default: 2,
        },
    },
    setup(props) {
        return () => {
            const { normal, red, green } = styles;
            let labelClass = [
                styles.StandardNumberLabel,
                { normal, red, green }[props.color] || normal,
            ];
            if (isNullOrUndefined(props.value)) {
                return <div class={labelClass}>---</div>;
            }
            let absNumber = Math.abs(props.value);
            const numberFormatter = {
                origin: value => +value.toFixed(props.fixedCount),
                format: value =>
                    absNumber >= 10000
                        ? +(value / 10000).toFixed(props.fixedCount)
                        : +value.toFixed(props.fixedCount),
                percent: value => +(value * 100).toFixed(props.fixedCount),
            };
            let showText = numberFormatter[props.type](props.value);
            return (
                <div class={labelClass}>
                    <span>{props.prefix}</span>
                    {showText}
                    <span
                        v-show={props.type === 'format' && absNumber >= 10000}
                    >
                        万
                    </span>
                    <span v-show={props.type === 'percent'}>%</span>
                </div>
            );
        };
    },
});
