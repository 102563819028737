<template>
    <div class="cover-chart" v-show="!hideSelf">
        <ChartBox
            :name="props.name"
            :loading="loading"
            @mounted="renderChart"
            @resize="onViewResize"
        >
            <template #header>
                <n-button
                    size="tiny"
                    style="margin-top: 5px"
                    @click="onAnalysisBtnClick"
                >
                    <template #icon>
                        <n-icon>
                            <QueryStatsFilled />
                        </n-icon>
                    </template>
                    成本分析
                </n-button>
            </template>
        </ChartBox>
    </div>
</template>

<style lang="less">
@import '../../../../common/common.less';

.cover-chart {
    position: relative;
    height: 330px;
    .standard-border;
    .standard-shadow;
    border-radius: 8px;
    overflow: hidden;

    .selected-module {
        background-color: #fff;
        padding: 0 0 2px 10px;
    }
}
</style>

<script setup>
import { ref, h } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { NButton, NIcon } from 'naive-ui';
import { QueryStatsFilled } from '@vicons/material';
import * as echarts from 'echarts/core';
import {
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    GridComponent,
} from 'echarts/components';
import { LineChart } from 'echarts/charts';
import { LabelLayout, UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import { AdvancedTooltip, yAxisLabelFormatter } from '@/common/EChartsTools';

import { useDataFilterStore } from '@/stores/business/CostView';
import ChartBox from '@/components/ChartBox';
import DefaultTooltipBox from '@/components/TooltipBox/DefaultTooltip';
import { MVBizViewAPI } from '@/common/API';
import { useDateRange } from '@/common/QueryDateRange';
import { formatNumber } from '@/common/tools';
import {
    useModel,
    useNodes,
    useRouteWatcher,
    useGranularity,
    useTag,
    useFilters,
    formatAxisData,
    aliasRegionLabel,
    getSortedLegends,
} from './tools';
import Log from '@/common/log';

echarts.use([
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    CanvasRenderer,
    LabelLayout,
    GridComponent,
    LineChart,
    UniversalTransition,
]);

const props = defineProps({
    name: {
        type: String,
    },
    dimension: {
        type: String,
        default: 'all',
    },
});

const emits = defineEmits(['showAnalysis']);

const route = useRoute();

const router = useRouter();

const treeType = useModel(route);

const nodes = useNodes(route);

const dateRange = useDateRange(route);

const granularity = useGranularity(route);

const tag = useTag(route);

const filter = useFilters(route);

const dataFilterStore = useDataFilterStore();

let loading = ref(false);

let hideSelf = ref(false);

let echartInstance = null;

let aTooltip = new AdvancedTooltip();

function renderChart(dom) {
    echartInstance = echarts.init(dom);
}

function onViewResize({ width }) {
    echartInstance.resize({ width });
}

function loadData() {
    // 查询条件中没有标签选择的时候，标签成本分布图不用请求数据
    if (props.dimension === 'tag' && !tag.value) {
        hideSelf.value = true;
        return;
    }
    loading.value = true;
    MVBizViewAPI.getViewData({
        ...dateRange.value,
        tree_type: treeType.value,
        node: nodes.value,
        dimension: props.dimension,
        granularity: granularity.value,
        ...(tag.value ? { tag: tag.value } : {}),
        ...filter.value,
    })
        .then(res => {
            loading.value = false;
            if (res.error_no !== 0) {
                return;
            }
            let resData = res.data;
            let legends = getSortedLegends(resData);

            // all，node，tag暂时不需要共享该数据
            if (!['all', 'node', 'tag'].includes(props.dimension)) {
                dataFilterStore.setOption(props.dimension, legends);
            }
            if (!resData || !legends || legends.length < 1) {
                hideSelf.value = true;
                return;
            }
            hideSelf.value = false;
            let axisData = Object.keys(resData[legends[0]]);
            echartInstance.setOption({
                grid: {
                    top: 10,
                    left: 56,
                    right: 140,
                    bottom: 30,
                },
                tooltip: aTooltip.tooltip({
                    valueFormatter: value => formatNumber(value),
                    vNodeCreator:
                        props.dimension === 'node'
                            ? (params, option) =>
                                  h(
                                      DefaultTooltipBox,
                                      {
                                          data: params,
                                          option,
                                      },
                                      {
                                          itemSuffix: slotProps => (
                                              <NButton
                                                  size="tiny"
                                                  ghost
                                                  color="#0B6EE2"
                                                  style="margin-left:8px"
                                                  onClick={() =>
                                                      onDrillDown(
                                                          slotProps.series
                                                              .seriesName
                                                      )
                                                  }
                                              >
                                                  下钻
                                              </NButton>
                                          ),
                                      }
                                  )
                            : null,
                    maxHeight: 200,
                    opt: {
                        position: (point, params, dom, rect, size) => {
                            let [x, y] = point;
                            let [cWidth, cHeight] = size.contentSize;
                            let [vWidth] = size.viewSize;
                            let positionX =
                                x < vWidth - cWidth ? x + 10 : x - cWidth - 10;
                            let positionY = y < 70 ? y + 10 : y - cHeight - 5;
                            return [
                                positionX,
                                positionY > -100 ? positionY : -100,
                            ];
                        },
                        backgroundColor: 'rgba(255,255,255,0.5)',
                    },
                }),
                legend: {
                    show: true,
                    type: 'scroll',
                    data: legends.map(key =>
                        props.dimension === 'region'
                            ? aliasRegionLabel(key)
                            : key
                    ),
                    orient: 'vertical',
                    right: 20,
                    top: 'middle',
                    textStyle: {
                        width: 80,
                        overflow: 'breakAll',
                    },
                    selector: [
                        {
                            // 全选
                            type: 'all',
                            // 可以是任意你喜欢的标题
                            title: '全选',
                        },
                        {
                            // 反选
                            type: 'inverse',
                            // 可以是任意你喜欢的标题
                            title: '反选',
                        },
                    ],
                },
                xAxis: {
                    type: 'category',
                    data: formatAxisData(axisData, granularity.value),
                },
                yAxis: {
                    type: 'value',
                    ...yAxisLabelFormatter(),
                },
                series: legends.map(key => ({
                    name:
                        props.dimension === 'region'
                            ? aliasRegionLabel(key)
                            : key,
                    type: 'line',
                    showSymbol: false,
                    data: axisData.map(
                        date =>
                            resData[key][date] && +resData[key][date].toFixed(2)
                    ),
                    triggerLineEvent: true,
                })),
            });
        })
        .catch(err => {
            loading.value = false;
            console.log(err);
        });
}

function onAnalysisBtnClick() {
    emits('showAnalysis', props.dimension);
}

function onDrillDown(nodeName) {
    router.push({
        params: {
            name: `${route.params.name}>${nodeName}`,
        },
        query: route.query,
    });
    Log.click('cost_view_drill_down');
}

loadData();

useRouteWatcher(route)(() => {
    if (route.params.name === '_default') {
        return;
    }
    echartInstance.clear();
    loadData();
});
</script>
