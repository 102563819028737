<template>
    <div class="ctrl-view">
        <div
            :class="{ label: true, clickable: !isIframe }"
            @click="showModelSwitch"
        >
            {{ treeType }} ：
        </div>
        <n-popover
            trigger="hover"
            placement="top-start"
            :duration="300"
            :show-arrow="false"
        >
            <template #trigger>
                <n-tree-select
                    v-model:value="mName"
                    class="input"
                    style="width: 120px; max-height: 300px"
                    :options="treeOpts"
                    size="small"
                    :consistent-menu-width="false"
                    :loading="isModuleLoading"
                    :default-expanded-keys="mNameExpandedKeys"
                    :render-suffix="
                        () => h('span', { style: { marginLeft: '30px' } })
                    "
                    virtual-scroll
                    filterable
                >
                    <template v-if="!isIframe" #action>
                        <n-button size="tiny" @click="redirectToNodeManage">
                            <template #icon>
                                <n-icon>
                                    <SettingsFilled />
                                </n-icon>
                            </template>
                            模块管理
                        </n-button>
                    </template>
                </n-tree-select>
            </template>
            <div class="module-path">
                模块路径：
                <template v-for="(item, index) in modulePath">
                    <n-button
                        size="small"
                        tag="a"
                        text
                        @click="onModulePathClick(item.key)"
                        >{{ item.name }}</n-button
                    >
                    <span
                        v-if="index < modulePath.length - 1"
                        style="margin: 0 4px"
                        >/</span
                    >
                </template>
            </div>
        </n-popover>
        <div class="label">日期：</div>
        <f-date-picker
            class="input"
            style="width: 205px"
            v-model:date="dateRes"
            :is-date-disabled="disableDate"
        ></f-date-picker>
        <div class="label">粒度：</div>
        <n-radio-group
            v-model:value="granularity"
            name="granularity"
            size="small"
            class="input"
            @update:value="onGranularityChange"
        >
            <n-radio-button label="日" value="day"></n-radio-button>
            <n-radio-button label="周" value="week"></n-radio-button>
            <n-radio-button label="相对周" value="7"></n-radio-button>
            <n-radio-button label="月" value="month"></n-radio-button>
        </n-radio-group>
        <div class="label">标签：</div>
        <n-select
            class="input"
            style="width: 80px"
            size="small"
            v-model:value="selectedTagK"
            :options="tagKOpts"
            :loading="isTagLoading"
            :consistent-menu-width="false"
            @clear="onTagKCleared"
            clearable
        >
            <template v-if="!isIframe" #action>
                <n-button size="tiny" @click="redirectToTagManage">
                    <template #icon>
                        <n-icon>
                            <SettingsFilled />
                        </n-icon>
                    </template>
                    标签管理
                </n-button>
            </template>
        </n-select>
        <div v-if="selectedTagK" class="label">=&nbsp;&nbsp;</div>
        <n-select
            v-if="selectedTagK"
            class="input"
            style="width: 80px"
            size="small"
            v-model:value="selectedTagV"
            :options="tagVOpts"
            :consistent-menu-width="false"
            clearable
            filterable
        >
        </n-select>
        <n-popover
            trigger="hover"
            placement="bottom-end"
            :duration="300"
            :show-arrow="false"
            v-model:show="isFilterPopShow"
        >
            <template #trigger>
                <n-badge :value="filterSelectedCount">
                    <n-button
                        size="tiny"
                        class="input"
                        quaternary
                        @click="clearFilters"
                    >
                        <template #icon>
                            <n-icon>
                                <FilterAltFilled />
                            </n-icon>
                        </template>
                        <span>{{
                            filterSelectedCount > 0 ? '重置' : '筛选'
                        }}</span>
                    </n-button>
                </n-badge>
            </template>
            <div class="more-input-view">
                <template v-for="item in filterViewCtrl">
                    <div class="label">{{ item.label }}</div>
                    <div class="input-line">
                        <n-select
                            class="input"
                            size="tiny"
                            v-model:value="filter[item.key]"
                            :options="
                                convertOption(
                                    dataFilterStore.options[item.key],
                                    item.key === 'region'
                                        ? aliasRegionLabel
                                        : null
                                )
                            "
                            :consistent-menu-width="false"
                            multiple
                            filterable
                            clearable
                        ></n-select>
                        <n-checkbox
                            class="exclude"
                            size="small"
                            v-model:checked="excludeKeys[item.key]"
                        >
                            <span style="font-size: 12px">排除</span>
                        </n-checkbox>
                    </div>
                </template>
                <div class="label">RI开关</div>
                <n-radio-group
                    v-model:value="filter.purchase"
                    name="ri_purchase"
                    class="switch"
                    size="small"
                >
                    <n-radio
                        v-for="item in RIOptions"
                        size="small"
                        :value="item.value"
                    >
                        <span style="font-size: 12px">{{ item.label }}</span>
                    </n-radio>
                </n-radio-group>
                <div class="label">SavingPlan未使用开关</div>
                <n-switch
                    v-model:value="filter.savingpu"
                    class="switch"
                    size="small"
                    :checked-value="1"
                    :default-value="0"
                />
            </div>
        </n-popover>
        <n-modal v-model:show="showModelSwitchModal">
            <n-card style="width: 300px" title="切换模块树" size="small">
                <n-select
                    v-model:value="selectedTreeModel"
                    :options="modelTreeOpts"
                ></n-select>
                <template #footer>
                    <div style="display: flex">
                        <n-button
                            style="margin-left: auto"
                            type="primary"
                            size="small"
                            :disabled="treeType === selectedTreeModel"
                            :loading="switchLoading"
                            @click="onSwitchTreeModelClick"
                            >确定</n-button
                        >
                    </div>
                </template>
            </n-card>
        </n-modal>
        <n-button
            style="margin-left: auto"
            size="small"
            type="primary"
            class="submit"
            @click="onQueryClick"
        >
            <template #icon>
                <n-icon>
                    <SearchFilled />
                </n-icon>
            </template>
            查询
        </n-button>
    </div>
</template>

<style lang="less" scoped>
@import '../../../../common/common.less';

.ctrl-view {
    .common-ctrl-bar;
    position: sticky;
    top: 0px;
    z-index: 2;

    .label {
        font-weight: bold;
        white-space: nowrap;
    }

    .input {
        margin-right: 10px;
    }

    .clickable {
        cursor: pointer;
        &:hover {
            background-color: #eee;
        }
    }
}

.more-input-view {
    // padding-right: 10px;
    .label {
        font-size: 12px;
        font-weight: bold;
        margin-bottom: 2px;
    }

    .input-line {
        display: flex;
        flex-direction: row;

        .exclude {
            margin-left: 8px;
        }
    }

    .input {
        width: 200px;
        margin-bottom: 10px;
    }

    .switch {
        margin-bottom: 10px;
    }
}
</style>

<script setup>
/**
 * 关于时间选择，默认-2是因为数据产出天然延迟，无法产出当天和前一天的数据，所以允许选择的end日期为当天向前推2天
 * 2023-02-01:延迟时间支持通过平台管理-系统配置热更新，无需前端发版
 */

import { ref, computed, watch, onMounted, h } from 'vue';
import { useRoute, useRouter, onBeforeRouteUpdate } from 'vue-router';
import {
    NButton,
    NSelect,
    NTreeSelect,
    NIcon,
    NPopover,
    NSwitch,
    NBadge,
    NRadio,
    NRadioButton,
    NRadioGroup,
    NModal,
    NCard,
    NCheckbox,
} from 'naive-ui';
import FDatePicker from '@/components/FinOpsDatePicker';
import {
    SearchFilled,
    FilterAltFilled,
    SettingsFilled,
} from '@vicons/material';
import dayjs from 'dayjs';
import { aliasRegionLabel, useModel } from './tools';
import { MVBizViewAPI } from '@/common/API';
import { useGlobalStore } from '@/stores/global';
import { useDataFilterStore } from '@/stores/business/CostView';
import { useNodesStore } from '@/stores/business/Nodes';
import Log from '@/common/log';
import UserTreeModel from './UserTreeModel';
import { debounce } from 'lodash';

const route = useRoute();

const router = useRouter();

const globalStore = useGlobalStore();

const dataFilterStore = useDataFilterStore();

const nodesStore = useNodesStore();

let treeType = useModel(route);

let mName = ref('');

let mNameExpandedKeys = ref([]);

let dateRes = ref({ type: 'last7' });

let treeOpts = ref([]);

let isModuleLoading = ref(false);

// 时间粒度：day，week，month
let granularity = ref('day');

let tagRes = ref([]);

let tagKOpts = computed(() =>
    tagRes.value.map(item => ({ label: item.name, value: item.name }))
);

let selectedTagK = ref(null);

let tagVOpts = computed(() => {
    if (!selectedTagK.value) {
        return [];
    }
    let selectedTagItem = tagRes.value.find(
        item => item.name === selectedTagK.value
    );
    return selectedTagItem
        ? selectedTagItem.children.map(item => ({ label: item, value: item }))
        : [];
});

let selectedTagV = ref(null);

let isTagLoading = ref(false);

function disableDate(value) {
    let subDayCount = globalStore.todayOffset * -1;
    return dayjs().subtract(subDayCount, 'day').valueOf() < value;
}

function genNodeExpendedKeys(name) {
    let nodePath = name.split('>');
    let expandedKeys = [];
    nodePath.forEach(pathName => {
        if (expandedKeys.length < 1) {
            expandedKeys.push(pathName);
            return;
        }
        expandedKeys.push(
            `${expandedKeys[expandedKeys.length - 1]}>${pathName}`
        );
    });
    return expandedKeys;
}

function onGranularityChange(value) {
    Log.click('cost_view_ctrl_granularity_' + value);
}

function onTagKCleared() {
    selectedTagV.value = null;
}

let filterViewCtrl = ref([
    {
        key: 'service',
        label: 'Service',
    },
    {
        key: 'detail',
        label: '细分计费',
    },
    {
        key: 'region',
        label: '地域',
    },
    {
        key: 'cloud',
        label: '云商',
    },
]);

let filter = ref({
    service: null,
    detail: null,
    region: null,
    cloud: null,
    purchase: 0,
    savingpu: 0,
});

let filterSelectedCount = computed(() => {
    let count = 0;
    for (let key in filter.value) {
        let obj = filter.value[key];
        if (Array.isArray(obj) && obj.length > 0) {
            count++;
            continue;
        }
        if (typeof obj === 'number' && obj > 0) {
            count++;
            continue;
        }
        if (typeof obj === 'string' && obj.length > 0) {
            count++;
        }
    }
    return count;
});

let isFilterPopShow = ref(false);

let RIOptions = ref([
    { label: '均摊RI', value: 0 },
    { label: '还原RI', value: 1 },
    { label: '排除RI', value: 2 },
]);

function convertOption(opts, labelFormatter) {
    return opts
        ? opts.map(name => ({
              label: labelFormatter ? labelFormatter(name) : name,
              value: name,
          }))
        : [];
}

function getFilterValues() {
    let values = {};
    for (let key in filter.value) {
        if (filter.value[key]) {
            values[key] = filter.value[key];
        }
    }
    return values;
}

function setFilterValues() {
    for (let key in filter.value) {
        let v = route.query[key];
        if (!v) {
            continue;
        }
        if (!['service', 'detail', 'region', 'cloud'].includes(key)) {
            filter.value[key] = +v;
            continue;
        }
        filter.value[key] = typeof v === 'object' ? v : [v];
    }
}

let excludeKeys = ref({
    service: false,
    detail: false,
    region: false,
    cloud: false,
});

function getExcludeSet() {
    let keys = Object.keys(excludeKeys.value).filter(
        key => excludeKeys.value[key]
    );
    if (!keys || keys.length < 1) {
        return {};
    }
    return {
        exkeys: keys.join(','),
    };
}

function setExcludeSet() {
    for (let key in excludeKeys.value) {
        let keys = route.query.exkeys ? route.query.exkeys.split(',') : [];
        excludeKeys.value[key] = keys.includes(key);
    }
}

function clearFilters() {
    filter.value = {
        service: null,
        detail: null,
        region: null,
        cloud: null,
        purchase: 0,
        savingpu: 0,
    };
    excludeKeys.value = {
        service: false,
        detail: false,
        region: false,
        cloud: false,
    };
}

function onModulePathClick(value) {
    mName.value = value;
    Log.click('cost_view_module_path_click');
}

function onQueryClick() {
    MVBizViewAPI.abortViewAndAnalysisRequest();
    let dateQuery = {};
    // 写route的规则：如果选择了相对时间区间，则只写入qbt值
    if (dateRes.value.type) {
        dateQuery.qdt = dateRes.value.type;
        Log.click('cost_view_date_use_qdt_' + dateQuery.qdt);
    } else {
        dateQuery.start = dateRes.value.range[0];
        dateQuery.end = dateRes.value.range[1];
        Log.click('cost_view_date_use_date');
    }
    router.replace({
        params: {
            name: mName.value,
        },
        query: {
            ...dateQuery,
            granularity: granularity.value,
            ...(selectedTagK.value && { tk: selectedTagK.value }),
            ...(selectedTagV.value && { tv: selectedTagV.value }),
            ...getFilterValues(),
            ...getExcludeSet(),
            t: dayjs().unix(),
            ...(route.query.iframe && { iframe: route.query.iframe }),
        },
    });
}

function initData(tree = treeType.value) {
    isModuleLoading.value = true;
    // 设置TreeSelect的default-expanded-keys，可能需要在组件mounted之前就完成
    mNameExpandedKeys.value = genNodeExpendedKeys(route.params.name);
    return nodesStore.getTreeStructNodes(tree).then(data => {
        isModuleLoading.value = false;
        treeOpts.value = data;
        return Promise.resolve(data);
    });
}

let nodeNameSplitStr = computed(() => {
    return mName.value.replaceAll('>', ',');
});

let isIframe = computed(() => route.query.iframe === '1');

function loadTags() {
    isTagLoading.value = true;
    MVBizViewAPI.getTags(treeType.value, nodeNameSplitStr.value).then(res => {
        isTagLoading.value = false;
        if (res.error_no !== 0) {
            return;
        }
        tagRes.value = res.data;
    });
}

watch(mName, (val, oldVal) => {
    // oldVal是空值时，表示此时页面刚加载，无需执行后续数据加载操作
    if (!oldVal) {
        return;
    }
    selectedTagK.value = null;
    selectedTagV.value = null;
    loadTags();
});

watch(
    treeType,
    debounce(() => {
        // treeType如果是因为路由跳转到其他页面，导致销毁，则无需loadTags
        treeType.value && loadTags();
    }, 500)
);

initData();

let modulePath = computed(() => {
    let moduleNameArray = route.params.name.split('>');
    let pathArray = [];
    moduleNameArray.forEach((item, index) => {
        let preItem = pathArray[index - 1];
        pathArray.push({
            name: item,
            key: preItem ? preItem.key + '>' + item : item,
        });
    });
    return pathArray;
});

onBeforeRouteUpdate(route => {
    mName.value = route.params.name;
});

function redirectToTagManage() {
    router.push(`/cost_tree/tag/${treeType.value}/list`);
}

function redirectToNodeManage() {
    router.push(`/cost_tree/node/${treeType.value}/list`);
}

let showModelSwitchModal = ref(false);

let modelTreeOpts = ref([]);

let selectedTreeModel = ref('');

function showModelSwitch() {
    if (isIframe.value) {
        return;
    }
    showModelSwitchModal.value = true;
    selectedTreeModel.value = treeType.value;
    MVBizViewAPI.getTreeList().then(res => {
        if (res.error_no !== 0) {
            console.log(res.error_msg);
            return;
        }
        let data = res.data;
        modelTreeOpts.value = data.map(item => ({
            label: item,
            value: item,
        }));
    });
}

let switchLoading = ref(false);

function onSwitchTreeModelClick() {
    switchLoading.value = true;
    UserTreeModel.set(selectedTreeModel.value);
    initData(selectedTreeModel.value).then(res => {
        showModelSwitchModal.value = false;
        switchLoading.value = false;
        let rootNodeName = res[0] && res[0].key;
        router.replace({
            params: {
                model: selectedTreeModel.value,
                name: rootNodeName || 'MV',
            },
        });
    });
}

onMounted(() => {
    // tags数据跟随mName值联动，mName首次获取值在mounted中，所以首次loadTags也需要在mounted后
    loadTags();
});

function created() {
    mName.value = route.params.name;
    if (route.query.start && route.query.end) {
        dateRes.value = { range: [+route.query.start, +route.query.end] };
    } else if (route.query.qdt) {
        dateRes.value = { type: route.query.qdt };
    }
    if (route.query.granularity) {
        granularity.value = route.query.granularity;
    }
    if (route.query.tk) {
        selectedTagK.value = route.query.tk;
        selectedTagV.value = route.query.tv || null;
    }
    setFilterValues();
    setExcludeSet();
}
created();
</script>
