<template>
    <div class="module-view">
        <template
            v-if="
                route.params.model !== 'no_permission' &&
                route.params.model !== '_default'
            "
        >
            <CtrlBar></CtrlBar>
            <div class="section-label">成本概览和趋势</div>
            <SummaryTrendView></SummaryTrendView>
            <div class="section-label">多视角数据分析</div>
            <MultiViewAnalysisView></MultiViewAnalysisView>
            <div v-if="route.query.iframe === '1'" class="copyright">Copyright © 2022~2023 MaxSaving</div>
        </template>
        <div
            v-if="route.params.model === 'no_permission'"
            class="no-permission-view"
        >
            <n-icon :size="40"><ErrorOutlineRound /></n-icon>
            您还未获得该功能的使用权限，请联系管理员gaowei，zhikun.bai，long.cai授权
        </div>
    </div>
</template>

<style lang="less" scoped>
@import '../../../common/common.less';

.module-view {
    .common-content;
    min-width: 1000px;

    padding-bottom: 60px;

    .section-label {
        font-size: 20px;
        font-weight: bold;
        padding: 10px 0 8px;
    }

    .back-btn {
        position: fixed;
        background-color: #fff;
        bottom: 10px;
        right: 14px;
    }

    .no-permission-view {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        height: 74vh;
    }

    .copyright {
        text-align: center;
        color: #999;
    }
}
</style>

<script setup>
import { onMounted } from 'vue';

import { useRouter, useRoute } from 'vue-router';

import { NIcon } from 'naive-ui';

import { ErrorOutlineRound } from '@vicons/material';

import { useUserStore, useGlobalStore } from '@/stores/global';

import { useRouteWatcher } from './components/tools';

import { isEmptyObject } from '@/common/tools';

import UserTreeModel from './components/UserTreeModel';

import CtrlBar from './components/ControlBar';

import SummaryTrendView from './components/SummaryTrendView';
import MultiViewAnalysisView from './components/MultiViewAnalysisView';

const router = useRouter();

const route = useRoute();

const userStore = useUserStore();

const globalStore = useGlobalStore();

function getCurrentTreeModel() {
    return UserTreeModel.get() || globalStore.globalConfig.defaultTree;
}

function defaultName() {
    let nodeMap = {
        biz_bu_mv: 'MV',
        biz_bu_mtg: 'MV>MTG',
        biz_bu_reyun: 'MV>热云',
        biz_bu_others: 'MV>其他',
    };
    if (!isEmptyObject(globalStore.globalConfig.defaultBUNodeMapv2)) {
        nodeMap =
            globalStore.globalConfig.defaultBUNodeMapv2[
                getCurrentTreeModel()
            ] || {};
    }
    for (let key in nodeMap) {
        if (userStore.currentUser.roles.includes(key)) {
            return nodeMap[key];
        }
    }
    return 'MV';
}

function routerReplace() {
    router.replace({
        params: {
            model: getCurrentTreeModel(),
            name: defaultName(),
        },
    });
}

useRouteWatcher(route)(() => {
    if (route.params.model === '_default') {
        routerReplace();
    }
});

onMounted(() => {
    if (route.params.model === '_default') {
        routerReplace();
    }
});
</script>
