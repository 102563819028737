/**
 * @file 业务成本分析
 * @description 数据筛选功能，筛选项内容是通过select选项提供，而筛选项列表则需要通过各视角数据下载后获取，此处需要共享状态
 */

import { defineStore } from 'pinia';

export const useDataFilterStore = defineStore('costDataFilter', {
    state: () => ({ opts: {} }),
    getters: {
        options(state) {
            return state.opts;
        },
    },
    actions: {
        setOption(key, option) {
            this.opts[key] = option;
        },
    },
});
