/**
 * @file UserSelectTreeModel
 * @description 管理用户最近选择的TreeModel的值，存储在localStroage
 */

const key = 'finops_user_select_tree_model';

export default class UserTreeModel {
    static set(value) {
        localStorage.setItem(key, value);
    }

    static get() {
        return localStorage.getItem(key);
    }
}
