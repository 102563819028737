<template>
    <div class="summary-trend-view">
        <div class="trend-view">
            <div class="trend-chart">
                <ChartBox
                    name="成本趋势"
                    @mounted="renderTrendChart"
                    @resize="onViewResize"
                    :loading="isTrendChartLoading">
                    <template #header>
                        <n-popover trigger="click" placement="bottom-end" raw>
                            <template #trigger>
                                <n-button
                                    size="tiny"
                                    style="margin-top: 5px"
                                    quaternary
                                    class="more">
                                    <template #icon>
                                        <NIcon size="small">
                                            <MoreHorizFilled />
                                        </NIcon>
                                    </template>
                                </n-button>
                            </template>
                            <div style="background-color: #fff; padding: 2px">
                                <n-button
                                    style="margin-right: 2px"
                                    size="tiny"
                                    quaternary
                                    block
                                    @click="copyTrendParam">
                                    导出OpenAPI参数</n-button
                                >
                                <n-button
                                    size="tiny"
                                    @click="exportCsvFile"
                                    quaternary
                                    block>
                                    导出CSV</n-button
                                >
                                <n-button
                                    size="tiny"
                                    @click="showAlarmCreator"
                                    quaternary
                                    block>
                                    创建成本监控
                                </n-button>
                                <n-button
                                    size="tiny"
                                    @click="showEventManage"
                                    quaternary
                                    block>
                                    成本事件管理
                                </n-button>
                            </div>
                        </n-popover>
                    </template>
                </ChartBox>
            </div>
            <n-spin :show="isSummaryLoading">
                <div class="summary-view">
                    <div class="item-box">
                        <div class="label">
                            <span class="date">{{ currentYear }}年</span
                            >累计总成本
                        </div>
                        <StandardNumberLabel
                            :value="summaryInfo.year.total"
                            prefix="$"></StandardNumberLabel>
                    </div>
                    <div class="item-box">
                        <div class="label">
                            <span class="date">{{ currentYear }}年</span
                            >平均日成本
                        </div>
                        <StandardNumberLabel
                            :value="summaryInfo.year.average"
                            prefix="$"></StandardNumberLabel>
                    </div>
                    <div class="item-box">
                        <div class="label">
                            <span class="date">{{ currentMonth }}月</span
                            >累计总成本
                        </div>
                        <StandardNumberLabel
                            :value="summaryInfo.month.total"
                            prefix="$"></StandardNumberLabel>
                    </div>
                    <div class="item-box">
                        <div class="label">
                            <span class="date">{{ currentMonth }}月</span
                            >平均日成本
                        </div>
                        <StandardNumberLabel
                            :value="summaryInfo.month.average"
                            prefix="$"></StandardNumberLabel>
                    </div>
                    <div class="item-box">
                        <div class="label">
                            <span class="date">第{{ currentWeek }}周</span
                            >累计总成本
                        </div>
                        <!-- <div class="date">{{ weekStart }} ~ {{ weekEnd }}</div> -->
                        <StandardNumberLabel
                            :value="summaryInfo.week.total"
                            prefix="$"></StandardNumberLabel>
                    </div>
                    <div class="item-box">
                        <div class="label">
                            <span class="date">第{{ currentWeek }}周</span
                            >平均日成本
                        </div>
                        <!-- <div class="date">{{ weekStart }} ~ {{ weekEnd }}</div> -->
                        <StandardNumberLabel
                            :value="summaryInfo.week.average"
                            prefix="$"></StandardNumberLabel>
                    </div>
                </div>
            </n-spin>
            <n-spin :show="isTrendChartLoading">
                <div class="diff-info-view">
                    <div class="item-box">
                        <div class="label">成本同比</div>
                        <div class="values-view">
                            <div class="value-item">
                                <div class="sub-label">比值</div>
                                <StandardNumberLabel
                                    type="percent"
                                    :value="
                                        diffYOYValues.rate
                                    "></StandardNumberLabel>
                            </div>
                            <div class="value-item">
                                <div class="sub-label">差值</div>
                                <StandardNumberLabel
                                    prefix="$"
                                    :value="
                                        diffYOYValues.diff
                                    "></StandardNumberLabel>
                                <NIcon class="trending-icon">
                                    <TrendingUpFilled
                                        class="up"
                                        v-if="diffYOYValues.diff > 0" />
                                    <TrendingDownFilled
                                        class="down"
                                        v-if="diffYOYValues.diff < 0" />
                                </NIcon>
                            </div>
                            <div class="value-item">
                                <n-switch
                                    v-if="granularity !== 'day'"
                                    size="small"
                                    v-model:value="isYOYPerDayShow">
                                    <template #checked>
                                        <span style="font-size: 12px"
                                            >日均</span
                                        >
                                    </template>
                                    <template #unchecked>
                                        <span
                                            style="
                                                font-size: 12px;
                                                color: #666;
                                            ">
                                            汇总
                                        </span>
                                    </template>
                                </n-switch>
                            </div>
                        </div>
                    </div>
                    <div class="item-box">
                        <div class="label">成本环比</div>
                        <div class="values-view">
                            <div class="value-item">
                                <div class="sub-label">比值</div>
                                <StandardNumberLabel
                                    type="percent"
                                    :value="
                                        diffChainValues.rate
                                    "></StandardNumberLabel>
                            </div>
                            <div class="value-item">
                                <div class="sub-label">差值</div>
                                <StandardNumberLabel
                                    prefix="$"
                                    :value="
                                        diffChainValues.diff
                                    "></StandardNumberLabel>
                                <NIcon class="trending-icon">
                                    <TrendingUpFilled
                                        class="up"
                                        v-if="diffChainValues.diff > 0" />
                                    <TrendingDownFilled
                                        class="down"
                                        v-if="diffChainValues.diff < 0" />
                                </NIcon>
                            </div>
                            <div class="value-item">
                                <n-switch
                                    v-if="granularity !== 'day'"
                                    size="small"
                                    v-model:value="isChainPerDayShow">
                                    <template #checked>
                                        <span style="font-size: 12px"
                                            >日均</span
                                        >
                                    </template>
                                    <template #unchecked>
                                        <span
                                            style="
                                                font-size: 12px;
                                                color: #666;
                                            ">
                                            汇总
                                        </span>
                                    </template>
                                </n-switch>
                            </div>
                        </div>
                    </div>
                </div>
            </n-spin>
        </div>
        <AlarmMiniCreator
            v-model:show="isAlarmCreatorShow"
            source-type="cost_tree"
            :source="alarmSource" />
        <EventManageModel
            v-model:show="isEventManageShow"
            :tree-name="treeType"
            :nodes="nodes"
            :related-events="relatedEventList"
            @update-related-event="loadRelatedEvents" />
        <EventModel
            v-model:show="isEventDetailShow"
            :related-events="relatedEventList"
            :selected-date="selectedEventDate" />
    </div>
</template>

<style lang="less" scoped>
@import '../../../../common/common.less';
.summary-trend-view {
    display: flex;
    flex-direction: row;

    .summary-view {
        display: flex;
        justify-content: space-between;
        flex-shrink: 0;
        margin-top: 16px;

        .item-box {
            border-left: 2px solid @theme-color;
            border-radius: 8px;
            background-color: #fff;
            padding: 10px 16px;
            flex-shrink: 0;
            display: flex;
            height: 46.6px;
            flex-direction: column;
            flex: 1 1 0;

            .standard-shadow;

            &:not(:first-child) {
                margin-left: 10px;
            }

            .label {
                font-size: 12px;
                font-weight: 500;
                color: @text-6-color;
                .date {
                    font-size: 14px;
                    color: @text-7-color;
                    margin-right: 4px;
                }
            }
        }
    }

    .trend-view {
        position: relative;
        flex-grow: 1;
        overflow: hidden;

        .trend-chart {
            position: relative;
            background-color: #fff;
            height: 350px;
            border-radius: 8px;
            overflow: hidden;
            .standard-shadow;
            .more {
                color: @theme-color;
            }
        }

        .diff-info-view {
            margin-top: 16px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .item-box {
                background-color: #fff;
                padding: 0px 20px;
                display: flex;
                height: 48px;
                line-height: 48px;
                flex: 1 1 0;
                border-radius: 8px;
                align-items: center;

                .standard-shadow;

                &:last-child {
                    margin-left: 10px;
                }

                .label {
                    color: @text-6-color;
                    height: 24px;
                    line-height: 24px;
                    font-size: 16px;
                    font-weight: bold;
                    padding-right: 32px;
                    margin-right: 32px;
                    border-right: 2px solid rgba(0, 0, 0, 0.2);
                }

                .values-view {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    flex: 1;

                    .value-item {
                        display: flex;
                        align-items: center;
                        .sub-label {
                            font-size: 12px;
                            color: @text-6-color;
                            font-weight: 500;
                            margin-right: 8px;
                        }

                        .trending-icon {
                            font-size: 20px;
                            margin-left: 4px;
                            .up {
                                color: #bc5151;
                            }
                            .down {
                                color: green;
                            }
                        }
                    }
                }
            }
        }
    }
    .date {
        font-size: 12px;
        color: #666;
    }
}
</style>

<script setup>
import { ref, computed, defineAsyncComponent } from 'vue';
import { useRoute } from 'vue-router';
import { NSpin, NSwitch, NButton, NIcon, NPopover, useMessage } from 'naive-ui';
import {
    MoreHorizFilled,
    TrendingUpFilled,
    TrendingDownFilled,
} from '@vicons/material';
import { StandardNumberLabel } from '@/components/JSXComponents';
import * as echarts from 'echarts/core';
import {
    GridComponent,
    LegendComponent,
    TooltipComponent,
    MarkPointComponent,
} from 'echarts/components';
import { LineChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import { yAxisLabelFormatter, exportMatrixData } from '@/common/EChartsTools';
import dayjs from 'dayjs';
import { formatNumber } from '@/common/tools';
import exportCSV from '@/common/CSVGenerator';

import {
    useModel,
    useNodes,
    useGranularity,
    useTag,
    useRouteWatcher,
    useFilters,
    formatAxisData,
} from './tools';
import { useDateRange } from '@/common/QueryDateRange';

import ChartBox from '@/components/ChartBox';
import AlarmMiniCreator from '@/views/Alarms/components/AlarmMiniCreator';

import { MVBizViewAPI, EventAPI } from '@/common/API';
import Log from '@/common/log';

const EventManageModel = defineAsyncComponent(() =>
    import('./EventManageModel')
);
const EventModel = defineAsyncComponent(() => import('./EventModel'));

dayjs.extend(require('dayjs/plugin/weekOfYear'));

const route = useRoute();

const message = useMessage();

const dateRange = useDateRange(route);

const treeType = useModel(route);

const nodes = useNodes(route);

const granularity = useGranularity(route);

const tag = useTag(route);

const filter = useFilters(route);

const currentMonth = computed(
    () => dayjs(dateRange.value.end_time).month() + 1
);

const currentYear = computed(() => dayjs(dateRange.value.end_time).year());

let isSummaryLoading = ref(false);

let summaryInfo = ref(genSummaryInfo());

function genSummaryInfo() {
    return {
        year: {
            total: 0,
            average: 0,
        },
        month: {
            total: 0,
            average: 0,
        },
        week: {
            total: 0,
            average: 0,
        },
    };
}

let currentWeek = ref(0);
let weekStart = ref('');
let weekEnd = ref('');

echarts.use([
    GridComponent,
    LineChart,
    CanvasRenderer,
    LegendComponent,
    UniversalTransition,
    TooltipComponent,
    MarkPointComponent,
]);

let trendChart = null;

let isTrendChartLoading = ref(false);

function renderTrendChart(dom) {
    trendChart = echarts.init(dom);
    trendChart.on('click', params => {
        isEventDetailShow.value = true;
        selectedEventDate.value = params.data.date;
        Log.click('cost_view_show_event_detail');
    });
}

function onViewResize({ width }) {
    trendChart.resize({ width });
}

function loadTotalData() {
    summaryInfo.value = genSummaryInfo();
    isSummaryLoading.value = true;
    MVBizViewAPI.getOverview({
        end_time: dateRange.value.end_time,
        tree_type: treeType.value,
        node: nodes.value,
        tag: tag.value,
        ...filter.value,
    })
        .then(res => {
            isSummaryLoading.value = false;
            if (res.error_no !== 0) {
                message.error(res.error_msg || '未知异常导致加载失败');
                return;
            }
            let resData = res.data;
            summaryInfo.value.year.total = +resData.year.toFixed(2);
            summaryInfo.value.year.average = +resData.year_avg.toFixed(2);
            summaryInfo.value.month.total = +resData.month.toFixed(2);
            summaryInfo.value.month.average = +resData.month_avg.toFixed(2);
            summaryInfo.value.week.total = +resData.week.toFixed(2);
            summaryInfo.value.week.average = +resData.week_avg.toFixed(2);
            currentWeek.value = dayjs(resData.week_start).week();
            weekStart.value = resData.week_start;
            weekEnd.value = resData.week_end;
        })
        .catch(err => {
            console.log(err);
            isSummaryLoading.value = false;
            message.error('未知异常导致加载失败');
        });
}

let diffValues = ref(genDiffValues());

function genDiffValues() {
    return {
        yoy: {
            diff: 0,
            rate: 0,
            diffPerDay: 0,
            ratePerDay: 0,
        },
        chain: {
            diff: 0,
            rate: 0,
            diffPerDay: 0,
            ratePerDay: 0,
        },
    };
}

let isYOYPerDayShow = ref(true);
let isChainPerDayShow = ref(true);

let diffYOYValues = computed(() => {
    return {
        diff: isYOYPerDayShow.value
            ? diffValues.value.yoy.diffPerDay
            : diffValues.value.yoy.diff,
        rate: isYOYPerDayShow.value
            ? diffValues.value.yoy.ratePerDay
            : diffValues.value.yoy.rate,
    };
});

let diffChainValues = computed(() => {
    return {
        diff: isChainPerDayShow.value
            ? diffValues.value.chain.diffPerDay
            : diffValues.value.chain.diff,
        rate: isChainPerDayShow.value
            ? diffValues.value.chain.ratePerDay
            : diffValues.value.chain.rate,
    };
});

let trendParam = '';

function loadTrendData() {
    diffValues.value = genDiffValues();
    trendChart && trendChart.clear();
    isTrendChartLoading.value = true;
    isYOYPerDayShow.value = true;
    isChainPerDayShow.value = true;
    let cycle = { day: 7, week: 4, 7: 4, month: 12 }[granularity.value] || 7;
    MVBizViewAPI.getCostContrast({
        ...dateRange.value,
        tree_type: treeType.value,
        node: nodes.value,
        dimension: 'all',
        cycle,
        granularity: granularity.value,
        ...(tag.value ? { tag: tag.value } : {}),
        ...filter.value,
    })
        .then(res => {
            isTrendChartLoading.value = false;
            if (res.error_no !== 0) {
                message.error(res.error_msg || '未知异常导致加载失败');
                return;
            }
            loadRelatedEvents();
            diffValues.value.yoy.diff = res.data.SP_diff || 0;
            diffValues.value.yoy.rate = res.data.SP_rate || 0;
            diffValues.value.yoy.diffPerDay = res.data.SP_diff_mean || 0;
            diffValues.value.yoy.ratePerDay = res.data.SP_rate_mean || 0;
            diffValues.value.chain.diff = res.data.LP_diff || 0;
            diffValues.value.chain.rate = res.data.LP_rate || 0;
            diffValues.value.chain.diffPerDay = res.data.LP_diff_mean || 0;
            diffValues.value.chain.ratePerDay = res.data.LP_rate_mean || 0;

            trendParam = res.data.param;

            let axisData = Object.keys(res.data.base);
            let localSeriesData = Object.values(res.data.base);
            let compareSeriesData = Object.values(res.data.contrast);
            let averageSeriesData = Object.values(res.data.trend);
            trendChart.setOption({
                grid: {
                    top: 20,
                    left: 56,
                    right: 30,
                    bottom: 50,
                },
                legend: {
                    show: true,
                    data: ['成本趋势', '7日平均', '成本同比'],
                    bottom: 4,
                },
                tooltip: {
                    trigger: 'axis',
                    appendToBody: true,
                    valueFormatter: value => formatNumber(value),
                },
                xAxis: {
                    type: 'category',
                    data: formatAxisData(axisData, granularity.value),
                },
                yAxis: {
                    type: 'value',
                    min: value => +(value.min - value.min / 10).toFixed(),
                    ...yAxisLabelFormatter(),
                },
                series: [
                    {
                        name: '成本趋势',
                        data: localSeriesData.map(
                            item => item && +item.toFixed(2)
                        ),
                        type: 'line',
                        showSymbol: false,
                        markPoint: {},
                    },
                    {
                        name: '7日平均',
                        data: averageSeriesData.map(
                            item => item && +item.toFixed(2)
                        ),
                        type: 'line',
                        showSymbol: false,
                    },
                    {
                        name: '成本同比',
                        data: compareSeriesData.map(
                            item => item && +item.toFixed(2)
                        ),
                        type: 'line',
                        showSymbol: false,
                    },
                ],
            });
        })
        .catch(err => {
            console.log(err);
            message.error('未知异常导致加载失败');
            isTrendChartLoading.value = false;
        });
}

let relatedEventList = ref([]);

function loadRelatedEvents() {
    let nodePath = route.params.name;
    EventAPI.getList(
        dayjs(dateRange.value.start_time).unix(),
        dayjs(dateRange.value.end_time).unix(),
        1,
        9999,
        `${treeType.value}/${nodePath}`
    ).then(res => {
        if (res.error_no !== 0) {
            return;
        }
        relatedEventList.value = res.data.list;
        if (relatedEventList.value.length > 0) {
            renderEventMarkPoints();
        }
    });
}

function getMergedRelatedEvents() {
    let map = {};
    relatedEventList.value.forEach(item => {
        if (map[item.date]) {
            map[item.date].count++;
            return;
        }
        map[item.date] = { title: item.title, date: item.date, count: 1 };
    });
    return Object.values(map);
}

function renderEventMarkPoints() {
    let opt = trendChart.getOption();
    opt.series[0].markPoint = {
        symbol: 'pin',
        symbolSize: 10,
        data: getMergedRelatedEvents().map(item => {
            let dateStr = dayjs(item.date * 1000).format('YYYY-MM-DD');
            let dateIndex = opt.xAxis[0].data.findIndex(
                date => date === dateStr
            );
            let dateVal = opt.series[0].data[dateIndex];
            return {
                name: `${item.title}${
                    item.count > 1 ? `(${item.count}个事件)` : ''
                }`,
                date: item.date,
                coord: [dateStr, dateVal],
                label: {
                    show: true,
                    color: '#900',
                    formatter: '{b}',
                    // 某个日期的事件数有多个，需要对offset y做一些调整
                    offset: [0, -12],
                },
            };
        }),
        itemStyle: { color: '#900' },
    };
    trendChart.setOption(opt);
}

let isEventDetailShow = ref(false);
let selectedEventDate = ref(null);

loadTotalData();
loadTrendData();

function exportCsvFile() {
    let data = exportMatrixData(trendChart);
    let columns = [
        { key: 'x', label: '时间' },
        ...Object.keys(data.columns).map(key => ({
            key,
            label: data.columns[key],
        })),
    ];
    exportCSV(data.list, columns);
}

function copyTrendParam() {
    if (!navigator.clipboard) {
        message.error(
            '当前环境不支持，原因可能是：非安全上下文（https）或浏览器版本过低'
        );
        return;
    }
    let paramStr = JSON.stringify(trendParam, null, 4);
    navigator.clipboard.writeText(paramStr).then(res => {
        message.success('OpenAPI参数已导出到剪贴板中...');
    });
}

let isAlarmCreatorShow = ref(false);

let alarmSource = ref(null);

function showAlarmCreator() {
    let source = trendParam;
    // 移除一些无用的参数项
    delete source['start_time'];
    delete source['end_time'];
    delete source['cycle'];

    alarmSource.value = source;
    isAlarmCreatorShow.value = true;
}

let isEventManageShow = ref(false);

function showEventManage() {
    isEventManageShow.value = true;
    Log.click('cost_view_show_event_manage');
}

useRouteWatcher(route)(() => {
    if (route.params.name === '_default') {
        return;
    }
    loadTotalData();
    loadTrendData();
});
</script>
